body {
    background: #334;
    color: white;
}
.App {
  text-align: left;
}

.App.tracks {
    margin: 30px;
}
.track {
    margin-bottom: 30px;
    padding: 0px 10px;
}
.track input {
    padding: 13.5px 14px;
}
.track-latency {
    text-align: right;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.MuiAppBar-root .MuiOutlinedInput-root.Mui-disabled,
.MuiAppBar-root .MuiFilledInput-root.Mui-disabled {
 background-color: #fff;
}
.MuiAppBar-root .MuiInputBase-input {
    padding: 13.5px 14px;
  }

.MuiAppBar-root span.centered, 
.MuiAppBar-root span.centered svg {
  line-height: 44px;
  vertical-align: middle;
  color: #fff;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
